import '../styles/onlineinsurance.scss';

import React, { useState } from 'react';

import fetchHelper from '../helper/fetch-helper';

function App() {

  const clickevent = ( e )=>{
    e.preventDefault;

  };

  const [ carousel1, setCarousel1 ] = useState( 1 );
  const [ carousel2, setCarousel2 ] = useState( 1 );

  const upCarousel1 = ( e ) => {
    e.preventDefault();
    setCarousel1( Math.abs( ( carousel1 + 1 ) % 3 ) + 1 );
  };

  const downCarousel1 = ( e ) => {
    e.preventDefault();
    setCarousel1( Math.abs( ( carousel1 - 1 ) % 3 ) + 1 );
  };

  const upCarousel2 = ( e ) => {
    e.preventDefault();
    setCarousel2( Math.abs( ( carousel1 + 1 ) % 3 ) + 1 );
  };

  const downCarousel2 = ( e ) => {
    e.preventDefault();
    setCarousel2( Math.abs( ( carousel1 - 1 ) % 3 ) + 1 );
  };

  return (

    <div
      id="onlineinsurance"
      className="hs-content-id-52966021681 hs-landing-page hs-page "
    >
      <div className="header-container-wrapper">
        <div className="header-container container-fluid">
          <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget "
                data-widget-type="custom_widget"
                data-x="0"
                data-w="12"
              >
                <div
                  id="hs_cos_wrapper_module_1628228136020185"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <div className="header">
                    <div className="container2">
                      <div
                        id="hs_cos_wrapper_module_1628228136020185_"
                        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                        data-hs-cos-general-type="widget"
                        data-hs-cos-type="inline_rich_text"
                        data-hs-cos-field="headline"
                      >
                        <a href="https://www.getmybubble.com/">
                          <img
                            src="https://online-insurance.getmybubble.com/hubfs/1-HomeLife-Landing/unnamed.png"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="body-container-wrapper">
        <div className="body-container container-fluid">
          <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget "
                data-widget-type="custom_widget"
                data-x="0"
                data-w="12"
              >
                <div
                  id="hs_cos_wrapper_module_1628226807617100"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <div id="ban" className="bannerSection">
                    <div className="container2">
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-6 padleft">
                          <div className="inner">
                            <div
                              id="hs_cos_wrapper_module_1628226807617100_"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="inline_rich_text"
                              data-hs-cos-field="headline_text"
                            >
                              <h1>
                                Smart. Fast. Reliable
                                <br />
                                Insurance
                              </h1>

                              <p>
                                When life hits you with floods, wildfires,
                                theft, and other perils that may affect you and
                                your home, we can help you and your loved ones
                                stand back up.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12 col-lg-6 formarea padright">
                          <div className="form">
                            <div
                              id="hs_cos_wrapper_module_1628226807617100_"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="inline_rich_text"
                              data-hs-cos-field="text"
                            >
                              <p className="formhead">Get insured in Minutes</p>

                              <p className="formsubhead">
                                Smart and Affordable Home &amp; Life insurance
                              </p>
                            </div>

                            <span
                              id="hs_cos_wrapper_module_1628226807617100_"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_form"
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="form"
                            >
                              <h3
                                id="hs_cos_wrapper_form_674550154_title"
                                className="hs_cos_wrapper form-title"
                                data-hs-cos-general-type="widget_field"
                                data-hs-cos-type="text"
                              ></h3>

                              <div id="hs_form_target_form_674550154">
                                <form
                                  noValidate=""
                                  acceptCharset="UTF-8"
                                  action="https://www.getmybubble.com"
                                  encType="multipart/form-data"
                                  id="hsForm_96114b1c-2511-4218-aca9-7f0bd3ecdb69_6005"
                                  method="GET"
                                  className="hs-form stacked hs-custom-form hs-form-private hsForm_96114b1c-2511-4218-aca9-7f0bd3ecdb69 hs-form-96114b1c-2511-4218-aca9-7f0bd3ecdb69 hs-form-96114b1c-2511-4218-aca9-7f0bd3ecdb69_89545f4a-898e-4807-9e0b-0c4fb894d5d9"
                                  data-form-id="96114b1c-2511-4218-aca9-7f0bd3ecdb69"
                                  data-portal-id="8926444"
                                  target="target_iframe_96114b1c-2511-4218-aca9-7f0bd3ecdb69_6005"
                                  data-reactid=".hbspt-forms-0"
                                >
                                  <script>
                                    document.getElementById("hsForm_96114b1c-2511-4218-aca9-7f0bd3ecdb69_6005").addEventListener("onclick",
                                    {/* function(event){event.preventDefault()}); */}
                                  </script>

                                  <noscript data-reactid=".hbspt-forms-0.2"></noscript>

                                  <div
                                    className="hs_submit hs-submit"
                                    data-reactid=".hbspt-forms-0.5"
                                  >
                                    <div
                                      className="hs-field-desc"
                                      data-reactid=".hbspt-forms-0.5.0"
                                    ></div>

                                    <div
                                      className="actions"
                                      data-reactid=".hbspt-forms-0.5.1"
                                    ></div>
                                  </div>

                                  <div className="radio">
                                    <div className="radio-text">

                                      <input type="radio" name="radio" /> Home &
                                    Life
                                    </div>

                                    <div className="radio-text">

                                      <input type="radio" name="radio" /> Home
                                    </div>

                                    <div className="radio-text">

                                      <input type="radio" name="radio" /> Life
                                    </div>
                                  </div>

                                  <div className="user-details">
                                    <div className="inputbox">
                                      <input
                                        type="text"
                                        placeholder="First Name"
                                        required
                                      />
                                    </div>

                                    <div className="inputbox">
                                      <input
                                        type="text"
                                        placeholder="Last Name"
                                        required
                                      />
                                    </div>

                                    <div className="inputbox">
                                      <input
                                        type="text"
                                        placeholder="Phone Number"
                                        required
                                      />
                                    </div>

                                    <div className="inputbox">
                                      <input
                                        type="text"
                                        placeholder="Email"
                                        required
                                      />
                                    </div>

                                    <div className="inputbox HomeAddress">
                                      <input
                                        type="text"
                                        placeholder="Home Address"
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div className="button" onClick={clickevent}>
                                    <input
                                      type="submit"
                                      value="Instant Quote in 30 Seconds"
                                    />
                                  </div>

                                  <p className="belowButton">
                                    Your information is safe with us – see how
                                    we protect your data
                                  </p>
                                </form>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="modal fade"
                    id="registration"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-body">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                          >
                            x
                          </button>

                          <h3 className="modal-title">
                            "Thank you for sharing your details with us. Someone
                            from our team will get back to you shortly."
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget "
                data-widget-type="custom_widget"
                data-x="0"
                data-w="12"
              >
                <div
                  id="hs_cos_wrapper_module_162823651248794"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <div id="threecolumn" className="threeColSection">
                    <div className="container2">
                      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 nopad">
                        <div className="threeCol">
                          <div
                            id="hs_cos_wrapper_module_162823651248794_"
                            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text discount-with-home-text"
                            data-hs-cos-general-type="widget"
                            data-hs-cos-type="inline_rich_text"
                            data-hs-cos-field="headline"
                          >
                            <h4 className="colorBlack textcenter">
                              Partnered with the best
                            </h4>
                          </div>

                          <ul>
                            <li>
                              <div
                                id="hs_cos_wrapper_module_162823651248794_"
                                className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                data-hs-cos-general-type="widget"
                                data-hs-cos-type="inline_rich_text"
                                data-hs-cos-field="column1_content"
                              >
                                <img
                                  src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Group%2049.png"
                                  alt=""
                                />
                              </div>
                            </li>

                            <li className="middle">
                              <div
                                id="hs_cos_wrapper_module_162823651248794_"
                                className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                data-hs-cos-general-type="widget"
                                data-hs-cos-type="inline_rich_text"
                                data-hs-cos-field="column2_content"
                              >
                                <img
                                  src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Group%2047.png"
                                  alt=""
                                />
                              </div>
                            </li>

                            <li>
                              <div
                                id="hs_cos_wrapper_module_162823651248794_"
                                className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                data-hs-cos-general-type="widget"
                                data-hs-cos-type="inline_rich_text"
                                data-hs-cos-field="column3_content"
                              >
                                <img
                                  src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Group%2048.png"
                                  alt=""
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row-fluid-wrapper row-depth-1 row-number-3 ">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget "
                data-widget-type="custom_widget"
                data-x="0"
                data-w="12"
              >
                <div
                  id="hs_cos_wrapper_module_1628846795158172"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <div id="whoweworkwith" className="fivecolSection">
                    <div className="container2">
                      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 nopad">
                        <div
                          id="hs_cos_wrapper_module_1628846795158172_"
                          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                          data-hs-cos-general-type="widget"
                          data-hs-cos-type="inline_rich_text"
                          data-hs-cos-field="headline"
                        >
                          <h2>Home Insurance</h2>
                        </div>

                        <div className="fiveBox">
                          <ul>
                            <li>
                              <div className="boxColor card1">
                                <div className="img">
                                  <img src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Frame%20(2).svg" alt="Insuranceimg" />
                                </div>

                                <div
                                  id="hs_cos_wrapper_module_1628846795158172_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column1_bottom_text"
                                >
                                  <p>Discounts that save you money</p>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="boxColor card1">
                                <div className="img">
                                  <img src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Frame%20(3).svg" alt="Insuranceimg" />
                                </div>

                                <div
                                  id="hs_cos_wrapper_module_1628846795158172_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column2_bottom_text"
                                >
                                  <p>
                                    Covers most personal items including
                                    appliances, furniture, electronics, and
                                    clothing
                                  </p>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="boxColor card1">
                                <div className="img">
                                  <img src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Group%2084.svg" alt="Insuranceimg" />
                                </div>

                                <div
                                  id="hs_cos_wrapper_module_1628846795158172_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column4_bottom_text"
                                >
                                  <p>
                                    Pays the replacement cost for your home's
                                    contents
                                  </p>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="boxColor card1">
                                <div className="img">
                                  <img src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Group%2087.svg" alt="Insuranceimg" />
                                </div>

                                <div
                                  id="hs_cos_wrapper_module_1628846795158172_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column3_bottom_text"
                                >
                                  <p>
                                    Pays claims based on the cost to rebuild
                                    your home
                                  </p>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="boxColor card1">
                                <div className="img">
                                  <img src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Frame%20(4).svg" alt="Insuranceimg" />
                                </div>

                                <div
                                  id="hs_cos_wrapper_module_1628846795158172_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column_5_text"
                                >
                                  <p>
                                    Pays for hotel expenses incurred in case
                                    your home is too damaged to live in
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row-fluid-wrapper row-depth-1 row-number-4 ">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget "
                data-widget-type="custom_widget"
                data-x="0"
                data-w="12"
              >
                <div
                  id="hs_cos_wrapper_module_1629106392120303"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <div id="whoweworkwith" className="fourcolSection fourcol2">
                    <div className="container2">
                      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 nopad">
                        <div
                          id="hs_cos_wrapper_module_1629106392120303_"
                          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                          data-hs-cos-general-type="widget"
                          data-hs-cos-type="inline_rich_text"
                          data-hs-cos-field="headline"
                        >
                          <h2>Discounts with Home Insurance</h2>
                        </div>

                        <div className="NewfourBox">
                          <ul>
                            <li>
                              <div className="circle">
                                <div
                                  id="hs_cos_wrapper_module_1629106392120303_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column1_top_text"
                                >
                                  <div className="cic">
                                    <div className="topCircle secondc">
                                      <span>Up to</span>

                                      <br />8

                                      <sup className="percent">%</sup>
                                    </div>
                                  </div>

                                  <p>Married / With Partner</p>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="circle">
                                <div
                                  id="hs_cos_wrapper_module_1629106392120303_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column2_top_text"
                                >
                                  <div className="topCircle">
                                    <span>Up to</span>

                                    <br />

                                    10<sup className="percent">%</sup>
                                  </div>

                                  <p>
                                    Roof made with clay, terra cotta, slate,
                                    concrete, or metal
                                  </p>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="circle">
                                <div
                                  id="hs_cos_wrapper_module_1629106392120303_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column3_top_text"
                                >
                                  <div className="cic">
                                    <div className="topCircle">
                                      <span>Up to</span>

                                      <br />

                                      10<sup className="percent">%</sup>
                                    </div>
                                  </div>

                                  <p>
                                    Construction by an accredited builder
                                    (current year)
                                  </p>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="circle">
                                <div
                                  id="hs_cos_wrapper_module_1629106392120303_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column4_top_text"
                                >
                                  <div className="cic">
                                    <div className="topCircle">
                                      <span>Up to</span>

                                      <br />

                                      10<sup className="percent">%</sup>
                                    </div>
                                  </div>

                                  <p>Newly acquired home (first year)</p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div
                          id="hs_cos_wrapper_module_1629106392120303_"
                          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                          data-hs-cos-general-type="widget"
                          data-hs-cos-type="inline_rich_text"
                          data-hs-cos-field="text"
                        ></div>

                        <div className="positive">
                          <img src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/add.png" alt="Insuranceimg" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row-fluid-wrapper row-depth-1 row-number-5 ">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget "
                data-widget-type="custom_widget"
                data-x="0"
                data-w="12"
              >
                <div
                  id="hs_cos_wrapper_module_1628857145338178"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <div
                    id="whoweworkwith"
                    className="fivecolSection fivecolSection2"
                  >
                    <div className="container2">
                      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 nopad">
                        <div
                          id="hs_cos_wrapper_module_1628857145338178_"
                          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                          data-hs-cos-general-type="widget"
                          data-hs-cos-type="inline_rich_text"
                          data-hs-cos-field="headline"
                        >
                          <h2>
                            Add Life Insurance to your Home Insurance purchase!
                            <span>Protects your mortgage too!</span>
                          </h2>
                        </div>

                        <div className="fiveBox">
                          <ul>
                            <li>
                              <div className="boxColor card1">
                                <div className="img">
                                  <img src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Group%2083%20(2).svg" alt="Insuranceimg" />
                                </div>

                                <div
                                  id="hs_cos_wrapper_module_1628857145338178_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column1_bottom_text"
                                >
                                  <p>
                                    Start with the coverage you need now and add
                                    more as you need later
                                  </p>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="boxColor card1">
                                <div className="img">
                                  <img src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Frame%20(5).svg" alt="Insuranceimg" />
                                </div>

                                <div
                                  id="hs_cos_wrapper_module_1628857145338178_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column2_bottom_text"
                                >
                                  <p>
                                    Lock in your premium and keep it! Your
                                    health rating locks in when you first buy
                                    the policy
                                  </p>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="boxColor card1">
                                <div className="img">
                                  <img src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Frame%20(6).svg" alt="Insuranceimg" />
                                </div>

                                <div
                                  id="hs_cos_wrapper_module_1628857145338178_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column4_bottom_text"
                                >
                                  <p>
                                    Offers you additional options to meet your
                                    needs over the years
                                  </p>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="boxColor card1">
                                <div className="img">
                                  <img src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Frame%20(7).svg" alt="Insuranceimg" />
                                </div>

                                <div
                                  id="hs_cos_wrapper_module_1628857145338178_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column3_bottom_text"
                                >
                                  <p>
                                    If you're young and healthy, you may not
                                    need a medical test
                                  </p>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="boxColor card1">
                                <div className="img">
                                  <img src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Frame%20(8).svg" alt="Insuranceimg" />
                                </div>

                                <div
                                  id="hs_cos_wrapper_module_1628857145338178_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column_5_text"
                                >
                                  <p>
                                    Smart &amp; transparent - know exactly what
                                    your policy offers you without any hidden
                                    costs
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row-fluid-wrapper row-depth-1 row-number-6 ">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget "
                data-widget-type="custom_widget"
                data-x="0"
                data-w="12"
              >
                <div
                  id="hs_cos_wrapper_module_1628286512070124"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <div className="Twocontentsection slider">
                    <div className="container2">
                      <div
                        id="hs_cos_wrapper_module_1628286512070124_"
                        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                        data-hs-cos-general-type="widget"
                        data-hs-cos-type="inline_rich_text"
                        data-hs-cos-field="heading"
                      >
                        <h2>
                          Life insurance premium <span>(per month)</span>
                        </h2>
                      </div>

                      <div className="flex col-xs-12 col-sm-12 col-lg-12 col-md-12 nopad swiper-container">
                        <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6 content swipes">
                          <div className="LeftContent">
                            <img src="https://online-insurance.getmybubble.com/hubfs/Group%2051.svg" alt="Insuranceimg" />

                            <div
                              id="hs_cos_wrapper_module_1628286512070124_"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="inline_rich_text"
                              data-hs-cos-field="content"
                            >
                              <div
                                id="myCarousel"
                                className="carousel slide"
                                data-ride="carousel"
                              >
                                <ol className="carousel-indicators">
                                  <li
                                    className=""
                                    data-target="#myCarousel"
                                    data-slide-to="0"
                                  >
                                    &nbsp;
                                  </li>

                                  <li
                                    data-target="#myCarousel"
                                    data-slide-to="1"
                                    className=""
                                  >
                                    &nbsp;
                                  </li>
                                </ol>

                                <div className="carousel-inner">
                                  <div className={`item ${carousel1 === 1 ? 'active' : ''}`}>
                                    { carousel1 === 1 &&
                                    <img
                                      src="https://online-insurance.getmybubble.com/hubfs/1-HomeLife-Landing/Frame%202.svg"
                                      alt=""
                                    /> }
                                  </div>

                                  <div className={`item ${carousel1 === 2 ? 'active' : ''}`}>
                                    { carousel1 === 2 &&
                                    <img
                                      src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/group-53.svg"
                                      alt=""
                                    />
                                    }
                                  </div>

                                  <div className={`item ${carousel1 === 3 ? 'active' : ''}`}>
                                    { carousel1 === 3 &&
                                    <img
                                      src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Group%2090%20(1).svg"
                                      alt=""
                                    />
                                    }
                                  </div>
                                </div>

                                <a
                                  className="left carousel-control"
                                  href="#myCarousel"
                                  data-slide="prev"
                                  onClick={ downCarousel1 }
                                >
                                  <span className="glyphicon glyphicon-chevron-left"></span>

                                  <span className="sr-only">Previous</span>
                                </a>

                                <a
                                  className="right carousel-control"
                                  href="#myCarousel"
                                  data-slide="next"
                                  onClick={ upCarousel1 }
                                >
                                  <span className="glyphicon glyphicon-chevron-right"></span>

                                  <span className="sr-only">Next</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6 content swipes">
                          <div className="RightContent">
                            <img src="https://online-insurance.getmybubble.com/hubfs/Group%2052.svg" alt="Insuranceimg" />

                            <div
                              id="hs_cos_wrapper_module_1628286512070124_"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="inline_rich_text"
                              data-hs-cos-field="right_content"
                            >
                              <div
                                id="myCarousel2"
                                className="carousel slide"
                                data-ride="carousel"
                              >
                                <ol className="carousel-indicators">
                                  <li
                                    className=""
                                    data-target="#myCarousel2"
                                    data-slide-to="0"
                                  >
                                    &nbsp;
                                  </li>

                                  <li
                                    data-target="#myCarousel2"
                                    data-slide-to="1"
                                    className="active"
                                  >
                                    &nbsp;
                                  </li>
                                </ol>

                                <div className="carousel-inner">
                                  { carousel2 === 1 &&
                                    <div className="item active">
                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/1-HomeLife-Landing/Frame%202%20(1).svg"
                                        alt=""
                                      />
                                    </div>
                                  }

                                  { carousel2 === 2 &&
                                    <div className="item active">
                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/group-54.svg"
                                        alt=""
                                      />
                                    </div>
                                  }

                                  { carousel2 === 3 &&
                                    <div className="item active">
                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Group%2089.svg"
                                        alt=""
                                      />
                                    </div>
                                  }
                                </div>

                                <a
                                  className="left carousel-control"
                                  href="#myCarousel2"
                                  data-slide="prev"
                                  onClick={ downCarousel2 }
                                >
                                  <span className="glyphicon glyphicon-chevron-left"></span>

                                  <span className="sr-only">Previous</span>
                                </a>

                                <a
                                  className="right carousel-control"
                                  href="#myCarousel2"
                                  data-slide="next"
                                  onClick={ upCarousel2 }
                                >
                                  <span className="glyphicon glyphicon-chevron-right"></span>

                                  <span className="sr-only">Next</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        id="hs_cos_wrapper_module_1628286512070124_"
                        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                        data-hs-cos-general-type="widget"
                        data-hs-cos-type="inline_rich_text"
                        data-hs-cos-field="bottom_text"
                      >
                        <p className="bottomtextq">
                          *Life insurance premiums are estimated averages for
                          healthy individuals with no prior medical history.
                          Individual rates may vary based on other underwriting
                          questions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row-fluid-wrapper row-depth-1 row-number-7 ">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget "
                data-widget-type="custom_widget"
                data-x="0"
                data-w="12"
              >
                <div
                  id="hs_cos_wrapper_module_1628285039427119"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <div id="whoweworkwith" className="fourcolSection">
                    <div className="container2">
                      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 nopad">
                        <div
                          id="hs_cos_wrapper_module_1628285039427119_"
                          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                          data-hs-cos-general-type="widget"
                          data-hs-cos-type="inline_rich_text"
                          data-hs-cos-field="headline"
                        >
                          <h2>Customer testimonials</h2>
                        </div>

                        <div className="fourBox">
                          <ul>
                            <li>
                              <div className="boxColor card1">
                                <div
                                  id="hs_cos_wrapper_module_1628285039427119_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column1_top_text"
                                >
                                  <div className="topSection">
                                    <div className="boxLeftImage">
                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Star%203.svg"
                                        width="38"
                                        alt=""
                                      />

                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Star%203.svg"
                                        width="38"
                                        alt=""
                                      />

                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Star%203.svg"
                                        width="38"
                                        alt=""
                                      />

                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Star%203.svg"
                                        width="38"
                                        alt=""
                                      />

                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Star%203.svg"
                                        width="38"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div
                                  id="hs_cos_wrapper_module_1628285039427119_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column1_middle_text"
                                >
                                  <p className="middleContent">Marvin W</p>

                                  <p className="middleContent2">
                                    Casa Grande, Arizona
                                  </p>
                                </div>

                                <div
                                  id="hs_cos_wrapper_module_1628285039427119_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column1_bottom_text"
                                >
                                  <p>
                                    Home and life insurance is very important to
                                    me. I'm a career trucker that's always on
                                    the move. I can't always be home to take
                                    care of my family, America needs to keep on
                                    moving. I drive on the roads of uncertainty,
                                    where anything can happen and it usually
                                    does. Bubble insurance has become my
                                    security blanket against those worries.
                                    Being a new home owner makes it essential to
                                    not only secure my families best interest
                                    while I'm here, but just in case I'm not I
                                    know my family will be OK. Thank you Bubble
                                    Insurance.
                                  </p>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="boxColor">
                                <div
                                  id="hs_cos_wrapper_module_1628285039427119_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column2_top_text"
                                >
                                  <div className="topSection">
                                    <div className="boxLeftImage">
                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Star%203.svg"
                                        width="38"
                                        alt=""
                                      />

                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Star%203.svg"
                                        width="38"
                                        alt=""
                                      />

                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Star%203.svg"
                                        width="38"
                                        alt=""
                                      />

                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Star%203.svg"
                                        width="38"
                                        alt=""
                                      />

                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Star%203.svg"
                                        width="38"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div
                                  id="hs_cos_wrapper_module_1628285039427119_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column2_middle_text"
                                >
                                  <p className="middleContent">Lauren S</p>

                                  <p className="middleContent2">
                                    Lake Elsinore, California
                                  </p>
                                </div>

                                <div
                                  id="hs_cos_wrapper_module_1628285039427119_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column2_bottom_text"
                                >
                                  <p>
                                    Freedom &amp; safety are most important to
                                    me, especially in a post-2020 world.
                                    Insurance makes it so I feel some comfort
                                    knowing whatever may happen, I'm covered and
                                    safe financially. My experience with Bubble
                                    was friendly, efficient, &amp; transparent.
                                    They were able to match or beat the other
                                    quotes I provided in a timely manner. Keep
                                    up the great work! I would refer them over!
                                  </p>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="boxColor">
                                <div
                                  id="hs_cos_wrapper_module_1628285039427119_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column3_top_text"
                                >
                                  <div className="topSection">
                                    <div className="boxLeftImage">
                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Star%203.svg"
                                        width="38"
                                        alt=""
                                      />

                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Star%203.svg"
                                        width="38"
                                        alt=""
                                      />

                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Star%203.svg"
                                        width="38"
                                        alt=""
                                      />

                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Star%203.svg"
                                        width="38"
                                        alt=""
                                      />

                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Star%203.svg"
                                        width="38"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div
                                  id="hs_cos_wrapper_module_1628285039427119_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column3_middle_text"
                                >
                                  <p className="middleContent">Ed W</p>

                                  <p className="middleContent2">
                                    San Diego, California
                                  </p>
                                </div>

                                <div
                                  id="hs_cos_wrapper_module_1628285039427119_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column3_bottom_text"
                                >
                                  <p>
                                    Recently had a fire inside the electrical
                                    box at our house. Damage was contained
                                    within the panel box, but with a 3 and 1
                                    year old in the house, it needed to be fixed
                                    quickly. Stillwater Insurance understood
                                    that, and allowed repairs to begin even
                                    before they inspected the damage. By the
                                    time they got out to see the damage, the
                                    panel had already been replaced. Stillwater
                                    came through....
                                  </p>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="boxColor">
                                <div
                                  id="hs_cos_wrapper_module_1628285039427119_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column4_top_text"
                                >
                                  <div className="topSection">
                                    <div className="boxLeftImage">
                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Star%203.svg"
                                        width="38"
                                        alt=""
                                      />

                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Star%203.svg"
                                        width="38"
                                        alt=""
                                      />

                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Star%203.svg"
                                        width="38"
                                        alt=""
                                      />

                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Star%203.svg"
                                        width="38"
                                        alt=""
                                      />

                                      <img
                                        src="https://online-insurance.getmybubble.com/hubfs/New%20Landing%20Page/Star%205.svg"
                                        width="38"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div
                                  id="hs_cos_wrapper_module_1628285039427119_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column4_middle_text"
                                >
                                  <p className="middleContent">Lorrie S</p>

                                  <p className="middleContent2">
                                    Mountain House, California
                                  </p>
                                </div>

                                <div
                                  id="hs_cos_wrapper_module_1628285039427119_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="inline_rich_text"
                                  data-hs-cos-field="column4_bottom_text"
                                >
                                  <p>
                                    My water heater broke New Years Day and
                                    flooded my garage but also flooded into my
                                    house. Danielle is the claims rep. Danielle
                                    is very professional, honest and caring. She
                                    is quick to respond and very helpful. In
                                    today's world it is hard to find such good
                                    customer service and caring people. Danielle
                                    has been a blessing and beyond grateful for
                                    her. I would highly recommend Stillwater
                                    Insurance.
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <footer>
      <div className="footer-container-wrapper">
        <div className="footer-container container-fluid">
          <div className="footer flexColumn">

            <div className="flex">
              <div className="col-md-6 col-sm-6 leftcontent">
                <div className="footerlogo">
                  <a
                    href="https://www.getmybubble.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://online-insurance.getmybubble.com/hubfs/Landing%20Page%20Images%20Folder/footerlogo.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 rightcontent">
                <div
                  id="hs_cos_wrapper_module_1628229994855201_"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="inline_rich_text"
                  data-hs-cos-field="social_media_icons"
                >
                  <a
                    href="https://www.facebook.com/GetMyBubble"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerfb.png?width=24&amp;name=footerfb.png"
                      width="24"
                      srcSet="https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerfb.png?width=12&amp;name=footerfb.png 12w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerfb.png?width=24&amp;name=footerfb.png 24w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerfb.png?width=36&amp;name=footerfb.png 36w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerfb.png?width=48&amp;name=footerfb.png 48w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerfb.png?width=60&amp;name=footerfb.png 60w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerfb.png?width=72&amp;name=footerfb.png 72w"
                      sizes="(max-width: 24px) 100vw, 24px"
                      alt="Insuranceimg"
                    />
                  </a>

                  <span>&nbsp;</span>

                  <span>&nbsp;</span>

                  <a
                    href="https://www.instagram.com/bubble_insurance/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/Instagram.png?width=24&amp;name=Instagram.png"
                      width="24"
                      srcSet="https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/Instagram.png?width=12&amp;name=Instagram.png 12w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/Instagram.png?width=24&amp;name=Instagram.png 24w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/Instagram.png?width=36&amp;name=Instagram.png 36w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/Instagram.png?width=48&amp;name=Instagram.png 48w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/Instagram.png?width=60&amp;name=Instagram.png 60w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/Instagram.png?width=72&amp;name=Instagram.png 72w"
                      sizes="(max-width: 24px) 100vw, 24px"
                      alt="Insuranceimg"
                    />
                  </a>

                  <span>&nbsp;</span>

                  <span>&nbsp;</span>

                  <a
                    href="https://twitter.com/getmybubble"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerTwitter.png?width=24&amp;name=footerTwitter.png"
                      width="24"
                      srcSet="https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerTwitter.png?width=12&amp;name=footerTwitter.png 12w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerTwitter.png?width=24&amp;name=footerTwitter.png 24w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerTwitter.png?width=36&amp;name=footerTwitter.png 36w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerTwitter.png?width=48&amp;name=footerTwitter.png 48w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerTwitter.png?width=60&amp;name=footerTwitter.png 60w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerTwitter.png?width=72&amp;name=footerTwitter.png 72w"
                      sizes="(max-width: 24px) 100vw, 24px"
                      alt="Insuranceimg"
                    />
                  </a>

                  <span>&nbsp;</span>

                  <span>&nbsp;</span>

                  <a
                    href="https://www.linkedin.com/authwall?trk=gf&amp;trkInfo=AQH9ruIliLDxWgAAAXkSgGFQMqAZZYQyC0MhmgEiyby2SR8F6LVR9yqy7FGhOEmocQfk140m2BJcIotocTnHYZ-WkZI-t-7T2eGY0QVHTskrxfs4aCLrFkawi49vvDGz2yglkP8=&amp;originalReferer=https://www.getmybubble.com/&amp;sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Favigupta%2F"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerLinkedIN.png?width=24&amp;name=footerLinkedIN.png"
                      width="24"
                      srcSet="https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerLinkedIN.png?width=12&amp;name=footerLinkedIN.png 12w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerLinkedIN.png?width=24&amp;name=footerLinkedIN.png 24w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerLinkedIN.png?width=36&amp;name=footerLinkedIN.png 36w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerLinkedIN.png?width=48&amp;name=footerLinkedIN.png 48w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerLinkedIN.png?width=60&amp;name=footerLinkedIN.png 60w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerLinkedIN.png?width=72&amp;name=footerLinkedIN.png 72w"
                      sizes="(max-width: 24px) 100vw, 24px"
                      alt="Insuranceimg"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="flex">

              <div className="flexColumn">
                <p>
                    Bubble's mission is to be the simplest, easiest,
                    and the most convenient way for people to protect themselves,
                    their family, and properties for complete peace of mind.
                </p>

                <p className="fbttm" style={{ opacity: 1 }}>
                  ©Bubble Inc, 2021 | All rights reserved<br/>

                  <span className="btfoot">Made with ❤️ in California.</span>
                </p>

              </div>

              <div className="flexColumn">
                <p className="fbttm">
                    License page
                </p>

                <p className="fbttm">
                    Disclaimers:
                  <span>
                    Bubble Insurance Solutions (“Bubble”) is a general agent for insurance companies, such as Stillwater Insurance Group, Pacific Life Insurance Company, and The Savings Bank Mutual Life Insurance Company of Massachusetts [SBLI]. Bubble is licensed as a property casualty and life insurance agency in CA, AZ, NJ, VA, NC, OH, and TX. Availability and qualification for coverage, terms, rates, and discounts may vary by jurisdiction. Bubble receives compensation based on the premiums for the insurance policies it sells. Please refer to Terms and Conditions for additional information.
                  </span>
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </footer> */}

      <div className="footer-container-wrapper">
        <div className="footer-container container-fluid">
          <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
            <div className="row-fluid ">
              <div
                className="span12 widget-span widget-type-custom_widget "
                data-widget-type="custom_widget"
                data-x="0"
                data-w="12"
              >
                <div
                  id="hs_cos_wrapper_module_1628229994855201"
                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                  data-hs-cos-general-type="widget"
                  data-hs-cos-type="module"
                >
                  <div className="footer bigfooter">
                    <div className="container2">
                      <div
                        id="hs_cos_wrapper_module_1628229994855201_"
                        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                        data-hs-cos-general-type="widget"
                        data-hs-cos-type="inline_rich_text"
                        data-hs-cos-field="headline"
                      >

                        <div className="row">
                          <div className="col-md-6 col-sm-6 leftcontent">
                            <div className="footerlogo">
                              <a
                                href="https://www.getmybubble.com/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src="https://online-insurance.getmybubble.com/hubfs/Landing%20Page%20Images%20Folder/footerlogo.png"
                                  alt=""
                                />
                              </a>
                            </div>
                          </div>

                          <div className="col-md-6 col-sm-6 rightcontent">
                            <div
                              id="hs_cos_wrapper_module_1628229994855201_"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="inline_rich_text"
                              data-hs-cos-field="social_media_icons"
                            >
                              <a
                                href="https://www.facebook.com/GetMyBubble"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src="https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerfb.png?width=24&amp;name=footerfb.png"
                                  width="24"
                                  srcSet="https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerfb.png?width=12&amp;name=footerfb.png 12w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerfb.png?width=24&amp;name=footerfb.png 24w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerfb.png?width=36&amp;name=footerfb.png 36w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerfb.png?width=48&amp;name=footerfb.png 48w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerfb.png?width=60&amp;name=footerfb.png 60w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerfb.png?width=72&amp;name=footerfb.png 72w"
                                  sizes="(max-width: 24px) 100vw, 24px"
                                  alt="Insuranceimg"
                                />
                              </a>

                              <span>&nbsp;</span>

                              <span>&nbsp;</span>

                              <a
                                href="https://www.instagram.com/bubble_insurance/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src="https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/Instagram.png?width=24&amp;name=Instagram.png"
                                  width="24"
                                  srcSet="https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/Instagram.png?width=12&amp;name=Instagram.png 12w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/Instagram.png?width=24&amp;name=Instagram.png 24w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/Instagram.png?width=36&amp;name=Instagram.png 36w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/Instagram.png?width=48&amp;name=Instagram.png 48w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/Instagram.png?width=60&amp;name=Instagram.png 60w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/Instagram.png?width=72&amp;name=Instagram.png 72w"
                                  sizes="(max-width: 24px) 100vw, 24px"
                                  alt="Insuranceimg"
                                />
                              </a>

                              <span>&nbsp;</span>

                              <span>&nbsp;</span>

                              <a
                                href="https://twitter.com/getmybubble"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src="https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerTwitter.png?width=24&amp;name=footerTwitter.png"
                                  width="24"
                                  srcSet="https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerTwitter.png?width=12&amp;name=footerTwitter.png 12w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerTwitter.png?width=24&amp;name=footerTwitter.png 24w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerTwitter.png?width=36&amp;name=footerTwitter.png 36w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerTwitter.png?width=48&amp;name=footerTwitter.png 48w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerTwitter.png?width=60&amp;name=footerTwitter.png 60w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerTwitter.png?width=72&amp;name=footerTwitter.png 72w"
                                  sizes="(max-width: 24px) 100vw, 24px"
                                  alt="Insuranceimg"
                                />
                              </a>

                              <span>&nbsp;</span>

                              <span>&nbsp;</span>

                              <a
                                href="https://www.linkedin.com/authwall?trk=gf&amp;trkInfo=AQH9ruIliLDxWgAAAXkSgGFQMqAZZYQyC0MhmgEiyby2SR8F6LVR9yqy7FGhOEmocQfk140m2BJcIotocTnHYZ-WkZI-t-7T2eGY0QVHTskrxfs4aCLrFkawi49vvDGz2yglkP8=&amp;originalReferer=https://www.getmybubble.com/&amp;sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Favigupta%2F"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src="https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerLinkedIN.png?width=24&amp;name=footerLinkedIN.png"
                                  width="24"
                                  srcSet="https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerLinkedIN.png?width=12&amp;name=footerLinkedIN.png 12w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerLinkedIN.png?width=24&amp;name=footerLinkedIN.png 24w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerLinkedIN.png?width=36&amp;name=footerLinkedIN.png 36w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerLinkedIN.png?width=48&amp;name=footerLinkedIN.png 48w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerLinkedIN.png?width=60&amp;name=footerLinkedIN.png 60w, https://online-insurance.getmybubble.com/hs-fs/hubfs/Landing%20Page%20Images%20Folder/footerLinkedIN.png?width=72&amp;name=footerLinkedIN.png 72w"
                                  sizes="(max-width: 24px) 100vw, 24px"
                                  alt="Insuranceimg"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-md-6 col-sm-6 leftcontent">
                          <div
                            id="hs_cos_wrapper_module_1628229994855201_"
                            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                            data-hs-cos-general-type="widget"
                            data-hs-cos-type="inline_rich_text"
                            data-hs-cos-field="text_2"
                          >
                            <p>
                            Bubble's mission is to be the simplest, easiest,
                            and the most convenient way for people to protect
                            themselves, their family, and properties for
                            complete peace of mind.
                            </p>

                            <p className="fbttm">
                            ©Bubble Inc, 2021 | All rights reserved
                              <br />

                              <span className="btfoot">
                              Made with ❤️ in California.
                              </span>
                            </p>
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-6 rightcontent">
                          <div
                            id="hs_cos_wrapper_module_1628229994855201_"
                            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"
                            data-hs-cos-general-type="widget"
                            data-hs-cos-type="inline_rich_text"
                            data-hs-cos-field="text_3"
                          >
                            <p className="fbttm">
                              <a href="https://www.getmybubble.com/license-numbers">
                              License page
                              </a>
                            </p>

                            <p className="fbttm">Disclaimers:</p>

                            <p>
                            Bubble Insurance Solutions (“Bubble”) is a general
                            agent for insurance companies, such as Stillwater
                            Insurance Group, Pacific Life Insurance Company,
                            and The Savings Bank Mutual Life Insurance Company
                            of Massachusetts [SBLI]. Bubble is licensed as a
                            property casualty and life insurance agency in CA,
                            AZ, NJ, VA, NC, OH, and TX. Availability and
                            qualification for coverage, terms, rates, and
                            discounts may vary by jurisdiction. Bubble
                            receives compensation based on the premiums for
                            the insurance policies it sells. Please refer to{' '}

                              <a href="https://www.getmybubble.com/terms-of-service">
                              Terms and Conditions
                              </a>{' '}
                            for additional information.
                            </p>

                            <p className="topbtm fbttm">Discounts:</p>

                            <p className="topbtm">
                            May apply for a variety of reasons, including
                            married couples, recent homebuyers, and a range of
                            security and safety enhancing features, such as
                            gated community location, installed smoke alarm,
                            burglar alarm and others.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default App;
