import 'regenerator-runtime/runtime';

class FetchHelper{
  static async get( url, data = { params: null } ){
    if( data.params ) {
      const searchParams = new URLSearchParams( data.params );
      url = url + '?' + searchParams.toString();
    }

    const response = await fetch( url );
    const body = await response.json();
    return { data: body };
  }
}

export default FetchHelper;
